import {
  BOOKSHELF_LOADED,
  BOOK_DOWNLOAD_STATE_UPDATE,
  BOOK_DOWNLOAD_FINISHED,
  BOOK_DOWNLOAD_PROGRESS,
  BOOK_DOWNLOAD_START,
  BOOK_OPEN
} from '../actions/actionTypes';

const defaultState = {
  downloads: {}
};

function updateDownloadState(state, bookName, progress, isDownloaded) {
  if (typeof(isDownloaded) === 'undefined') {
    const book = state.downloads[bookName];
    isDownloaded = book && book.isDownloaded;
  }
  return {
    ...state,
    downloads: {
      ...state.downloads,
      [bookName]: {
        progress: progress,
        isDownloaded: isDownloaded
      }
    }
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case BOOKSHELF_LOADED:
      return {
        ...state,
        bookshelf: action.payload
      };
    // This case is used by the actual Android downloader.
    case BOOK_DOWNLOAD_STATE_UPDATE:
      return {
        ...state,
        ...action.payload
      };
    // These three are only for the fake downloader. The real state is updated directly above.
    case BOOK_DOWNLOAD_START:
      return updateDownloadState(state, action.payload.bookName, 0, false);
    case BOOK_DOWNLOAD_PROGRESS:
      return updateDownloadState(state, action.payload.bookName, action.payload.progress);
    case BOOK_DOWNLOAD_FINISHED:
      return updateDownloadState(state, action.payload.bookName, 100, true);
    default:
      return {
        ...state,
      };
  }
};
