function getBookDirUrl(book) {
  const stories_folder_name = book.stories_folder_name;
  return `/assets/Content/stories/${stories_folder_name}`;
}


function getVideoUrl(page, book, modePath) {
  modePath = modePath || 'data-storybook';
  const bookDir = getBookDirUrl(book);
  let
    v3SentenceVideo = page ? page.text_area.sentence_video && page.text_area.sentence_video : null,
    videoName = page ? page.sentenceVideoName : null,
    videoUrl = videoName ? (bookDir + `/${modePath}/` + videoName + '.mp4') : null;

  if (v3SentenceVideo && v3SentenceVideo.enabled) {
    videoUrl =  bookDir + `/${modePath}/` + v3SentenceVideo.video_filename;
  }

  return videoUrl;
};

function getGlossaryEntry(id, glossary) {
  return glossary && glossary.terms && glossary.terms.find(term => term.id === id);
}

function getGlossaryVideoUrl(id, glossary, book, modePath) {
  if (!glossary) return null;

  // TODO(GLOSSARY): if there's ever more than one glossary, figure out how to pass the
  // correct path here that works both on the glossary page and with linked_glossary from a read page.
  // modePath = modePath || 'data-glossary';
  modePath = 'data-glossary';
  const bookDir = getBookDirUrl(book);
  const entry = getGlossaryEntry(id, glossary);
  let movieName = (entry ? entry['video_filename'] : null);
  if (!movieName) {
    console.error('Error getting glossary video URL from pList, falling back to id ' + id);
    movieName = id ? id.toLowerCase() : null;
  }
  let
    videoUrl = movieName ? (bookDir + `/${modePath}/` + glossary.config.videos_subfolder + '/' + movieName + '.mp4') : null;

  return videoUrl;
};


export {getBookDirUrl, getVideoUrl, getGlossaryVideoUrl};
