import React, { Component } from 'react';
import './DownloadReadButton.css';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../../store';
import { BOOK_DOWNLOAD_START, BOOK_OPEN } from '../../actions/actionTypes';

import DownloadProgress from './DownloadProgress';
import { isBookDownloadingSupported } from '../../bookshelf/BookshelfMiddleware';

const mapStateToProps = (state, ownProps) => {
  // console.log('bookshelf', state);
  const downloadStatus = state.bookshelf.downloads[ownProps.book.stories_folder_name];
  return {
    isDownloaded: downloadStatus && downloadStatus.isDownloaded || !isBookDownloadingSupported(),
    progress: downloadStatus && downloadStatus.progress,
    currentBook: state.pages.currentBook
  }
};

const mapDispatchToProps = dispatch => ({

});

class DownloadReadButton extends Component {

  render() {
    const book = this.props.book;

    const onClickDownload = (ev) => {
      ev.stopPropagation();
      store.dispatch({type: BOOK_DOWNLOAD_START, payload: this.props.book});
      return false;
    };

    const progress = this.props.progress;
    const downloadLabel = progress > 0 ? 'Downloading...' : 'Download';

    return (
      <div className={`downloadReadButton ${this.props.isDownloaded ? 'read' : 'download'}`} style={this.props.style}>
          {this.props.isDownloaded ? 
            <Link to={'book/' + book.stories_folder_name}>Read</Link>
            :
            <button onClick={onClickDownload}>
              <svg width="105" height="40" viewBox="0 0 105 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="progressFull">
                  <rect x="0" y="0" width="105" height="40" fill="#10B4C9" />
                  <text className="downloadLabel" fill="#fff" x={progress > 0 ? 6 : 19} y="25">{downloadLabel}</text>
                </g>
                <g id="progressEmpty" clipPath="url(#progressEmptyClipPath)">
                  <rect x="0" y="0" width="105" height="40" fill="#fff" />
                  <text className="downloadLabel" fill="#10B4C9" x={progress > 0 ? 6 : 19} y="25">{downloadLabel}</text>
                </g>
                <defs>
                  <clipPath id="progressEmptyClipPath">
                    <rect x={progress > 0 ? progress * 105 : 0} y="0" width="105" height="40" fill="#FFFFFF" />
                  </clipPath>
                </defs>
              </svg>
              <span>{downloadLabel}</span>
            </button>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReadButton);
