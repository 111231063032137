import React, { Component } from 'react';
import './Watch.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../store';

import {getBookDirUrl} from '../utils/video';

import './Shaker.css';
import { debug } from '../utils/Debug';

const MAX_TRIGGER_ACCEL = 13;

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    bookDir: getBookDirUrl(state.pages.currentBook)
  };
};

const mapDispatchToProps = dispatch => ({});

class Shaker extends Component {

  triggerCount = 0;
  triggerCountResetTimeoutId = false;
  
  constructor(props) {
    super(props);
    this.state = {
      animationState: 'hidden'
    };

    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
  }

  componentDidMount() {
    debug("Registering accelerometer sensor");
    const registerSensor = () => {
      try {
        this.accel = new window.LinearAccelerationSensor({frequency: 60});

        this.accel.addEventListener('reading', () => {
          // debug("Acceleration along the X-axis " + this.accel.x);
          // debug("Acceleration along the Y-axis " + this.accel.y);
          // debug("Acceleration along the Z-axis " + this.accel.z);

          if (this.accel.x > MAX_TRIGGER_ACCEL || 
              this.accel.y > MAX_TRIGGER_ACCEL || 
              this.accel.z > MAX_TRIGGER_ACCEL) {
            
            debug("Shake triggered " + this.triggerCount);
            
            if (this.triggerCount++ == 8) {
              this.show();
            }

            if (this.triggerCountResetTimeoutId) {
              clearTimeout(this.triggerCountResetTimeoutId);
            }
            this.triggerCountResetTimeoutId = setTimeout(() => this.triggerCount = 0, 400);
          }
        });

        this.accel.start();
      } catch (e) {
        console.error('Failed to initialize accelerometer sensor', e);
      }
    };

    try {
      navigator.permissions.query({ name: 'accelerometer' })
        .then(result => {
            if (result.state === 'denied') {
              console.error('Permission to use accelerometer sensor is denied.');
            } else {
              registerSensor();
            }
        })
        .catch(e => {
          console.error('Failed to initialize accelerometer sensor', e);
          // try without querying for permission.
          registerSensor();
        });
    } catch (e) {
      console.error('Failed to initialize accelerometer sensor', e);
      // try without querying for permission.
      registerSensor();
    }
  }

  componentWillUnmount() {
    try {
      this.accel && this.accel.stop();
    } catch (e) {
      console.error('Failed to stop accelerometer sensor', e);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.animationState == 'shown' && prevState.animationState == 'hidden') {
      setTimeout(() => this.hide(), 3000);
    }
  }

  hide() {
    if (this.state.animationState == 'shown') {
      this.setState({animationState: 'hiding'});
      setTimeout(() => this.setState({animationState: 'hidden'}), 400);
    }
  }
  
  show() {
    if (this.state.animationState == 'hidden') {
      this.setState({animationState: 'shown'});
    }
  }
  
  render() {
    const imagePath = this.props.image;

    return (
      <div className={'Shaker ' + (this.state.animationState)} style={{backgroundImage: "url('" + imagePath + "')"}}
        onClick={this.hide}>
            
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shaker);
