import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../../store';
import { BOOK_DOWNLOAD_START, BOOK_OPEN, BOOK_SAVE_FAVORITES } from '../../actions/actionTypes';

import './FavoriteButton.css';
import 'animate.css';

const mapStateToProps = (state, ownProps) => {
  const favorites = state.favorites.favorites;
  return {
    favorites
  }
};

const mapDispatchToProps = dispatch => ({

});

const FavoriteButton = (props) => {
    const book = props.book;
    const isFavorite = !!props.favorites[book.stories_folder_name];

    const onFavoriteClicked = (ev) => {
        ev.stopPropagation();
        store.dispatch({
            type: BOOK_SAVE_FAVORITES, 
            payload: {
                ...props.favorites,
                [book.stories_folder_name]: !isFavorite
            }});
        return false;
    };

    return ( 
        <button 
            className={'favoriteButton animate__animated animate__fast' + (isFavorite ? ' isFavorite animate__rubberBand' : '')}
            onClick={onFavoriteClicked}
            >{isFavorite ? 'Unfavorite' : 'Favorite'}</button>
     );
}
 
export default connect(mapStateToProps, mapDispatchToProps)(FavoriteButton);