import React, { Component } from 'react';
import { APP_LOAD, REDIRECT, PAGE_LOAD, VIDEO_START, VIDEO_END } from '../actions/actionTypes';
import './Page.css';
import VideoPlayer from './VideoPlayer';
import StoryText from './StoryText';
import Shaker from './Shaker';
import { connect } from 'react-redux';
import { store, history } from '../store';
import { Link } from 'react-router-dom'

import posed from 'react-pose'
import {spring} from 'popmotion'
import transform from 'dom-css-transform';

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import {getBookDirUrl} from '../utils/video';
import { debug } from '../utils/Debug';

const VELOCITY_THRESHOLD = 1000;
const SLIDE_WIDTH = window.innerWidth;
const SWIPE_THRESHOLD = SLIDE_WIDTH / 4;


function getStoryUrl(page, storyDir) {
  let
    storyName = page ? page.text_area.page_content_html : null,
    storyUrl = storyName ? (storyDir + '/' + storyName) : null;

  return storyUrl;
};

function getPageBackground(page, storyDir) {
  return page ? storyDir + '/' + page.background_image_asset : '';
};

const mapStateToProps = (state, ownProps) => {
  const bookDir = getBookDirUrl(state.pages.currentBook);
  const modePath = (state.pages.modePath) || 'data-storybook';
  const storyDir = bookDir + '/' + modePath;
  const page = state.pages.page;
  const nextPage = state.pages && state.pages.pages && state.pages.pages[ownProps.pageIndex + 1],
        prevPage = state.pages && state.pages.pages && state.pages.pages[ownProps.pageIndex - 1];

  return {
   bookDir,
   storyDir,
   videoUrl: state.pages.videoUrl,
   storyText: getStoryUrl(page, storyDir),
   backgroundImage: getPageBackground(page, storyDir),
   nextBackgroundImage: getPageBackground(nextPage, storyDir),
   prevBackgroundImage: getPageBackground(prevPage, storyDir),
   page,
   nextPage,
   prevPage,
   numPages: (state.pages && state.pages.pages && state.pages.pages.length) || 0
  }
};

const mapDispatchToProps = dispatch => ({
  onPageLoad: (payload) =>
    dispatch({ type: PAGE_LOAD, payload }),
  showVideo: (payload) =>
    dispatch({ type: VIDEO_START, payload }),
  hideVideo: (payload) =>
    dispatch({ type: VIDEO_END, payload }),
  onRedirect: () =>
    dispatch({ type: REDIRECT })
});

class Page extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.foregroundPane = React.createRef();
  }

  // TODO: UGGGGH this seems so wrong. i wish there was a way to respond to LOCATION_CHANGE outside of a component or something.
  // https://hackernoon.com/evil-things-you-do-with-redux-dispatch-in-updating-lifecycle-methods-ad116de882d4
  componentWillReceiveProps(nextProps) {
    if (this.props.pageIndex !== nextProps.pageIndex) {
      this.loadPage(nextProps);
    }
  }

  componentWillMount() {
    this.loadPage(this.props);
  }

  loadPage(props) {
    let pageIndex = props.pageIndex;
    if (pageIndex >= 0) {
      this.props.onPageLoad(pageIndex);
    }
  }

  onClickReplay = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.loadPage(this.props);
    this.props.showVideo();
  }

  onSliderMove = (x) => {
    if (!this.isDragging) return;
    const pane = this.foregroundPane.current;
    debug("onSliderMove", x);

    if (pane) {
      const percentScrolled = Math.abs(x / SLIDE_WIDTH);
      //transform(pane, 'translateY(' + x + 'px)');
      pane.style.opacity = 1 - percentScrolled;
    }
  }

  onSlideFinished = () => {
    this.isDragging = false;
    if (this.currentSlideIdx === 1) {
      return;
    }
    let newPageIdx = this.props.pageIndex + (this.currentSlideIdx - 1);

    this.currentSlideIdx = 1;
    history.push("./" + newPageIdx);
  }

  onSlideDragStart = () => {
    this.isDragging = true;
  }

  render() {
    const currentSlideIdx = 1; // It's always 1 because we reset to the middle slide at the end of the animation!

    const getClosestSlideEdge = (velocity, to, from) => {
      let closestSlide = currentSlideIdx;
      if (velocity === 0) {
        const leftEdge = 0;
        const rightEdge = SLIDE_WIDTH;
        if (to < 0 && to < -SWIPE_THRESHOLD) {
          closestSlide = currentSlideIdx + 1;
        } else if (to > 0 && to > SWIPE_THRESHOLD) {
          closestSlide = currentSlideIdx - 1;
        }
      } else if (velocity < -VELOCITY_THRESHOLD) {
        closestSlide = currentSlideIdx + 1;
      } else if (velocity > VELOCITY_THRESHOLD) {
        closestSlide = currentSlideIdx - 1;
      }

      //debug(velocity + " to: " + to + " SWIPE_THRESHOLD: " + SWIPE_THRESHOLD + " currentSlideIdx: " + currentSlideIdx + " closestSlide: " + closestSlide)

      // hacky way to indicate to onSlideFinished where to go, without reloading by calling setState(). did react devs ever think about animation?
      this.currentSlideIdx = closestSlide;

      return closestSlide * -SLIDE_WIDTH + SLIDE_WIDTH;//(velocity > 0 || to == 0 ? window.innerWidth : -window.innerWidth);
    };

    const sliderConfig = {
        draggable: 'x',
        dragBounds: {
            left: this.props.nextBackgroundImage ? -SLIDE_WIDTH : 0,
            right: this.props.prevBackgroundImage ? SLIDE_WIDTH : 0
        },
        dragEnd: {
            transition: ({
                    from,
                    to,
                    velocity
                }) => {
                  const endTransition = spring({
                      from,
                      to: getClosestSlideEdge(velocity, to, from),
                      velocity,
                      stiffness: 750,
                      damping: 50,

                  });
                  endTransition.start({
                    complete: this.onSlideFinished
                  });

                  return endTransition;
                }
        }
    }

    const Slider = posed.div(sliderConfig);
    //const SliderHandle = posed.div(sliderConfig);


    const getExtraClassForPage = (pageIndex) => {
      debug(`getExtraClassForPage`, this.props);
      return pageIndex === this.props.numPages - 1 ? 'lastPage' : '';
    }

    return (
      <div className={`Page pageIndex_${this.props.pageIndex}`}>

        <Slider className="pageBackground" onValueChange={{ x: this.onSliderMove }} onDragStart={this.onSlideDragStart}>
          <div className={`backgroundSlide slidePageIndex_${this.props.pageIndex - 1} ${getExtraClassForPage(this.props.pageIndex - 1)}`} style={{backgroundImage: "url('" + this.props.prevBackgroundImage + "')"}}>
            {this.props.prevPage && this.props.prevPage.text_area.enabled &&
              <StoryText
                bookDir={this.props.storyDir} />
            }
          </div>
          <div className={`backgroundSlide slidePageIndex_${this.props.pageIndex} ${getExtraClassForPage(this.props.pageIndex)}`} style={{backgroundImage: "url('" + this.props.backgroundImage + "')"}}
              onClick={this.props.hideVideo}  onValueChange={{ x: this.onSliderMove }}>
              <div className="pageForeground" ref={this.foregroundPane}>
                <VideoPlayer url={this.props.videoUrl} />

                {this.props.videoUrl &&
                  <button
                    className="replayButton"
                    css={css`
                    background-image: url('${this.props.bookDir}/assets/read/button-play.png');

                    &:active {
                      background-image: url('${this.props.bookDir}/assets/read/button-play-highlighted.png');
                    }
                    `}
                    onClick={this.onClickReplay} >
                    Replay
                  </button>
                }

                {this.props.storyText && this.props.page.text_area.enabled &&
                  <StoryText
                    storyText={this.props.storyText}
                    bookDir={this.props.storyDir}
                    />
                }
          </div>
          </div>
          <div className={`backgroundSlide slidePageIndex_${this.props.pageIndex + 1} ${getExtraClassForPage(this.props.pageIndex + 1)}`} style={{backgroundImage: "url('" + this.props.nextBackgroundImage + "')"}}>
            {this.props.nextPage && this.props.nextPage.text_area.enabled &&
              <StoryText
                bookDir={this.props.storyDir} />
            }
          </div>

        </Slider>

        { this.props.page &&
          <Shaker
            page={this.props.page}
            image={this.props.storyDir + '/' + this.props.page.shake_background_image_asset} />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
