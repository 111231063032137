import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { contentLoaderMiddleware, promiseMiddleware, localStorageMiddleware } from './middleware';
import { bookDownloaderMiddleware } from './bookshelf/BookshelfMiddleware';
import { favoritesMiddleware } from './bookshelf/FavoritesMiddleware';
import reducer from './reducer';

import { routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(myRouterMiddleware, bookDownloaderMiddleware, contentLoaderMiddleware, promiseMiddleware, localStorageMiddleware, favoritesMiddleware);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(myRouterMiddleware, bookDownloaderMiddleware, contentLoaderMiddleware, promiseMiddleware, localStorageMiddleware, favoritesMiddleware, createLogger())
  }
};

export const store = createStore(
  reducer(history), composeWithDevTools(getMiddleware()));
