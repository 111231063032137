import React, { Component } from 'react';
import './Snackbar.css';

class Snackbar extends Component {

  render() {
    const onClickHide = () => {
      this.setState({
        isHidden: true
      });
    };

    return (
      <div className={"snackbar" + this.state.isHidden ? ' hidden' : ''} onClick={onClickHide}>
        <p>{this.props.message}</p>
        <p>{this.props.error}</p>
      </div>
    );
  }
}

export default (Snackbar);
