import React, { Component } from 'react';
import './StoryText.css'

import { VIDEO_LOAD, VIDEO_START } from '../actions/actionTypes';
import { connect } from 'react-redux';
import { store } from '../store';


const mapDispatchToProps = dispatch => ({
  onClickKeyword: (payload) => {
    dispatch({ type: VIDEO_LOAD, payload });
    dispatch({ type: VIDEO_START, payload })
  },
});


function sanitizeKeyword(keywordData) {
	if (typeof(keywordData) == 'object' && typeof(keywordData.keyword) == 'string') {
    // We have to accept all languages here so only remove characters we know could be used to hack.
		return keywordData.keyword.replace(/[\.\/\\:\&\%\#\@\!\*\+]/ig, "");
	}

	return false;
}

class StoryText extends Component {
  
  componentWillMount() {
  	this.receiveMessageBound = this.receiveMessage.bind(this);
    if (this.props.storyText) {
  	  window.addEventListener("message", this.receiveMessageBound, false);
    }
  }

  componentWillUnmount() {
    if (this.props.storyText) {
  	  window.removeEventListener("message", this.receiveMessageBound, false);
    }
  }

  receiveMessage(event) {
  	if (event.origin !== window.location.origin) {
  		console.error("ERROR: receiveMessage denied from unknown origin " + event.origin);
  	    return;
  	}

  	let keyword = sanitizeKeyword(event.data);
  	if (keyword) {
  		this.props.onClickKeyword(keyword);
  	}
  }

  render() {
    return (
      <div 
        className="StoryText"
        style={{backgroundImage: `url(${this.props.bookDir}/assets/text-area-background.png)`}}
        >

        {this.props.storyText && 
          <iframe title="Story Text" src={this.props.storyText + '?isWeb=true'} />}
          
      </div>
    );
  }
}


export default connect(() => { return {}}, mapDispatchToProps)(StoryText);
