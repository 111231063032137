import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Bookshelf from './components/bookshelf/Bookshelf';
import BookRouter from './components/BookRouter';
import * as serviceWorker from './serviceWorker';

import { ConnectedRouter } from 'connected-react-router'


import { Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store, history} from './store';

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history}>
	      <Switch>
          <Route path="/" exact component={Bookshelf} />
          <Route path="/index.html" exact component={Bookshelf} />
          <Route path="/book/:book" component={BookRouter} />
	      </Switch>
    </ConnectedRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
