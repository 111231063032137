import React, { Component } from 'react';
import './Watch.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../store';
import VideoPlayer from './VideoPlayer';
import HomeLinks from './HomeLinks';

import { VIDEO_START } from '../actions/actionTypes';

import {getBookDirUrl} from '../utils/video';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    bookDir: getBookDirUrl(state.pages.currentBook)
  };
};

const mapDispatchToProps = dispatch => ({
  onStart: (payload) =>
    dispatch({ type: VIDEO_START, payload }),
});

class Watch extends Component {

  componentDidMount() {
    this.props.onStart();
  }

  render() {
    const modePath = this.props.match.params.modePath || 'data-watchvideo';

    return (
      <div
        className="Watch"
        style={{backgroundImage: `url(${this.props.bookDir}/assets/watch/background.png)`}}
        >
          <VideoPlayer
            url={this.props.bookDir + `/${modePath}/fullstory.mp4`}
            showControls={true} />

          <HomeLinks
            showOnlyHome={true}
            bookDir={this.props.bookDir}
             />
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watch);
