import React, { Component } from 'react';
import './HomeLinks.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath, withRouter,  } from 'react-router';

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import Loading from './Loading';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    pages: state.pages
  };
};

const mapDispatchToProps = dispatch => ({});

class HomeLinks extends Component {

  render() {
    // this isn't loading for some reason so we do it manually
    // const path = matchPath("/book/:book", { path: this.props.match.path, exact: false });
    const path = this.props.match.path;
    const bookUrlRoot = path.substring(0, path.indexOf("/", path.indexOf("/", 1) + 1));
    // console.log("matchPath", this.props.match, bookUrlRoot);

    // const modeMatches = /mode\/([\w-]+)/.exec(path)
    // const curModePath = modeMatches ? modeMatches[1] : null;
    const curModePath = this.props.match.params.modePath;

    let HomeLink = (
      <Link to={{
        pathname: `${bookUrlRoot}/index`,
        state: {
          skipIntro: true
        }
      }}
       className={'Home-Link Home-Link_home ' + (this.props.showOnlyHome ? 'showOnlyHome Home-Link-Floating' : '')}
       css={css`
       background-image: url('${this.props.bookDir}/assets/home/button-home.png');

       &:active {
         background-image: url('${this.props.bookDir}/assets/home/button-home-highlighted.png');
       }
       `}>
         Home
      </Link>
      );

    if (this.props.showOnlyHome) {
      return HomeLink;
    }

    if (!this.props.pages.modes) {
      return <Loading />;
    }

    const lineBreakIndexes = this.props.pages.modesPerLineArrangement || [];
    // since the modesPerLineArrangement is how much each column should hold, we convert that to indexes.
    // e.g. [4, 4, 2] should convert to [4, 8, 10]:
    // 0, 1, 2, 3
    // 4, 5, 6, 7,
    // 8, 9,
    // 10
    for (let i = 1; i < lineBreakIndexes.length; i++) {
      lineBreakIndexes[i] = lineBreakIndexes[i - 1] + lineBreakIndexes[i];
    }

    let links = this.props.pages.modes
      .filter(mode => mode.path !== curModePath)
      .map((mode, i) => {
        const isLinebreak = lineBreakIndexes.includes(i);
        return (
          <>
            {isLinebreak && <div className='break' /> }
            <Link
              key={mode.path}
              to={`${bookUrlRoot}/${mode.type}/${mode.path}`}
              className="Home-Link"
              css={css`
              background-image: url('${this.props.bookDir}/${mode.image_asset_path}');

              &:active {
                background-image: url('${this.props.bookDir}/${mode.image_asset_path}');
              }
              `}>
                {mode.name}
            </Link>
          </>
        )
      });

    if (this.props.showHome) {
      links = [HomeLink, ...links];
    }

    return (
      <div className={`HomeLinks linebreaks_${lineBreakIndexes.length}`}>
        {links}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeLinks));
