import React, { Component } from 'react';
import './Watch.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../store';

import {isInPrimaryLocale} from '../utils/Translations';

import {getBookDirUrl} from '../utils/video';

import './TutorialOverlay.css';

const KEY_WAS_SHOWN = '__TutorialOverlay_KEY_WAS_SHOWN';

function shouldShow() {
  return !localStorage.getItem(KEY_WAS_SHOWN) && !sessionStorage.getItem(KEY_WAS_SHOWN);
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    modePath: state.pages.modePath || 'data-storybook',
    bookDir: getBookDirUrl(state.pages.currentBook)
  };
};

const mapDispatchToProps = dispatch => ({});

class TutorialOverlay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      animationState: 'hidden'
    };

    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.onClickNeverAgain = this.onClickNeverAgain.bind(this);
  }

  componentDidMount() {
    if (shouldShow()) {
      this.setState({
        animationState: 'shown'
      });
    }
  }

  hide() {
    sessionStorage.setItem(KEY_WAS_SHOWN, 'true');

    if (this.state.animationState == 'shown') {
      this.setState({animationState: 'hiding'});
      setTimeout(() => this.setState({animationState: 'hidden'}), 400);
    }
  }

  show() {
    if (this.state.animationState == 'hidden') {
      this.setState({animationState: 'shown'});
    }
  }

  onClickNeverAgain() {
    localStorage.setItem(KEY_WAS_SHOWN, 'true');
    this.hide();
  }

  render() {
    if (isInPrimaryLocale('en')) {
      return (
        <div className={'TutorialOverlay ' + (this.state.animationState)} onClick={this.hide}>
          <img className="close" src="/images/instructions-overlay/close.png" alt="close" />
          <img className="dont-show" src="/images/instructions-overlay/dont-show-again.png" alt="dont show again" onClick={this.onClickNeverAgain} />
          <img className="highlighted-words" src="/images/instructions-overlay/highlighted-words.png" alt="tap highlighted words" />
          <img className="pull-down" src="/images/instructions-overlay/pull-down.png" alt="pull down from top" />
          <img className="swipe" src="/images/instructions-overlay/swipe.png" alt="swipe" />
          <img className="tap-video" src="/images/instructions-overlay/tap-video.png" alt="tap for video" />
        </div>
      );
    } else {
      // Other languages aren't cut up nicely yet, so we just show the original from the Content folder in 3:4 aspect ratio
      return (
        <div className={'TutorialOverlay ' + (this.state.animationState)} onClick={this.hide}>
          <img
            className="fullOverlay"
            src={`${this.props.bookDir}/${this.props.modePath}/assets/instructions.png`}
            alt="tap highlighted words, pull down from top, swipe to change page, tap play for video" />
          <button className="dont-show" onClick={this.onClickNeverAgain}>Don't show again</button>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialOverlay);
