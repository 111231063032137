import React, { Component } from 'react';
import './Glossary.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../store';
import VideoPlayer from './VideoPlayer';
import HomeLinks from './HomeLinks';
import {isEqual} from 'lodash';

import { VIDEO_START, BOOK_LOAD_GLOSSARY } from '../actions/actionTypes';

import {getBookDirUrl, getGlossaryVideoUrl} from "../utils/video.js"

import {createLocaleComparator, translateString} from "../utils/Translations";
import Loading from './Loading';

const mapStateToProps = (state, ownProps) => {
  let word = ownProps.match.params.word;

  return {
   word,
   glossary: state.pages.bookGlossary,
   bookDir: getBookDirUrl(state.pages.currentBook),
   videoUrl: getGlossaryVideoUrl(word, state.pages.bookGlossary, state.pages.currentBook, state.pages.modePath)
  }
};

const mapDispatchToProps = dispatch => ({
  onStart: (payload) => dispatch({ type: VIDEO_START, payload }),
  loadGlossary: (payload) => dispatch({ type: BOOK_LOAD_GLOSSARY, payload })
});

class Glossary extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    // only kick off loadGlossary once
    if (!nextProps.glossary) {
      if (nextProps.bookDir && !this.props.bookDir) {
        return true;
      }
    };

    return !isEqual(nextProps, this.props);
  }

  // TODO: UGGGGH this seems so wrong. i wish there was a way to respond to LOCATION_CHANGE outside of a component or something.
  // https://hackernoon.com/evil-things-you-do-with-redux-dispatch-in-updating-lifecycle-methods-ad116de882d4
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.onStart();
  }

  componentDidMount() {
    this.props.onStart();
  }

  render() {
    if (!this.props.glossary) {
      if (this.props.bookDir) {
        this.props.loadGlossary({
          bookDir: this.props.bookDir,
          modePath: this.props.match.params.modePath
        });
      }
      return <Loading />;
    }

    const comparator = createLocaleComparator();
    const terms = this.props.glossary.terms.sort((a, b) =>
      comparator(a.display_text, b.display_text));
    const listItems = terms && terms.map((entry, i) =>
      <li>
        <Link key={i} to={'./' + entry.id} className="Glossary-word">
          {entry.display_text}
        </Link>
      </li>
    );
    return (
      <div
        className="Glossary"
        style={{backgroundImage: `url(${this.props.bookDir}/assets/learn/background.png)`}}
        >
        <div className="Glossary-panel">
          <ol className="Glossary-wordlist">
            {listItems}
          </ol>
        </div>

        <div className="Glossary-definition">

          <VideoPlayer url={this.props.videoUrl} />

          <h2 className='Glossary-word'>{this.props.word || translateString("Select a word")}</h2>

          <HomeLinks
            bookDir={this.props.bookDir}
            showOnlyHome={true} />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
