import {
    BOOK_SAVE_FAVORITES,
    BOOK_FAVORITES_LOADED,
    BOOKSHELF_FETCH
} from '../actions/actionTypes';

const KEY_FAVORITES = 'KEY_FAVORITES_V1';

export const favoritesMiddleware = store => next => action => {
  if (action.type === BOOK_SAVE_FAVORITES) {
    const favorites = action.payload;
    window.localStorage.setItem(KEY_FAVORITES, JSON.stringify(favorites));
    store.dispatch({ type: BOOK_FAVORITES_LOADED, payload: favorites });
  } else if (action.type === BOOKSHELF_FETCH) {
    const favoritesJson = window.localStorage.getItem(KEY_FAVORITES);
    const favorites = favoritesJson ? JSON.parse(favoritesJson) : {};
    store.dispatch({ type: BOOK_FAVORITES_LOADED, payload: favorites });
  }

  return next(action);
};

export const favoritesReducer = (state = {favorites: {}}, action) => {
  switch (action.type) {
    case BOOK_FAVORITES_LOADED:
      return {
        ...state,
        favorites: action.payload
      };
    default:
      return {
        ...state,
      };
  }
};
