import React, { Component } from 'react';
import './Book.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../../store';
import { BOOKSHELF_FETCH, BOOKSHELF_LOADED } from '../../actions/actionTypes';

import BookPreview from './BookPreview'
import DownloadReadButton from './DownloadReadButton';
import DownloadErrorButton from './DownloadErrorButton';
import FavoriteButton from './FavoriteButton';
import { flushSync } from 'react-dom';

const mapStateToProps = (state, ownProps) => {
  // console.log('bookshelf', state);
  const downloadStatus = state.bookshelf.downloads[ownProps.book.stories_folder_name];
  return {
    // isDownloaded: downloadStatus && downloadStatus.isDownloaded,
    // progress: downloadStatus && downloadStatus.progress,
    error: downloadStatus && downloadStatus.error,
    currentBook: state.pages.currentBook
  }
};

const mapDispatchToProps = dispatch => ({

});


class Book extends Component {
  constructor() {
    super();
    this.state = {
      shown: false,
      animatingPreview: "hiding hidden"
    };
  }

  render() {
    const onTogglePreview = () => {
      if (document.startViewTransition) {
        // View transition! much better
        document.startViewTransition(() => {
          flushSync(() => {
            this.setState({
              ...this.state,
              shown: !this.state.shown,
              // we use different names for hiding/showing classes so they don't trigger the fallback
              animatingPreview: this.state.shown ? "hidden" : "shown"
            });
          });
        });
      } else {
        // Fallback for browsers that don't support ViewTransition API:
        if (this.state.shown) {
          this.setState({
            ...this.state,
            animatingPreview: "hiding"
          }, () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                shown: !this.state.shown
              });
            }, 300);
          }); 
        } else {
          this.setState({
            ...this.state,
            shown: !this.state.shown,
            animatingPreview: "hiding"
          }, () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                animatingPreview: "showing",
              });
            }, 3);
          }); 
        }
      }
    };

    const book = this.props.book;
    return (
      <div className={"book " + this.state.animatingPreview} onClick={onTogglePreview}>
        <img src={'assets/Content/bookshelf/' + book.bookshelf_display.thumbnail} alt={book.bookshelf_display.title} />

        <div className="bookInfo">
          <div className="textInfo">
            <h3>{book.bookshelf_display.title}</h3>
            <p className='age'>Age 4+</p>
          </div>
          {this.props.error ? 
            <DownloadErrorButton 
              onClick={onTogglePreview} />
            :
            <DownloadReadButton 
              book={book} />
          }
        </div>

        <FavoriteButton 
            book={book}
        />

        {this.state.shown && 
          <BookPreview
            book={book}
            onClose={onTogglePreview} />}
      </div>
    );
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(Book);
