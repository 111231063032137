import React, { Component } from 'react';
import './DownloadProgress.css';

class DownloadProgress extends Component {

  render() {
    return (
      <div className="downloadProgress">
        Downloading... {this.props.progress}%
      </div>
    );
  }
}

export default DownloadProgress;
