import {
  PAGE_LOAD,
  REDIRECT,
  VIDEO_LOAD,
  BOOK_OPEN,
  BOOK_LOADED,
  BOOK_MODES_LOADED,
  BOOK_GLOSSARY_LOADED,
  BOOK_GLOSSARY_DATA_LOADED,
  ARTICLE_SUBMITTED,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_ARTICLE,
  ARTICLE_PAGE_UNLOADED,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED
} from '../actions/actionTypes';

import { debug } from '../utils/Debug';

import {getVideoUrl, getGlossaryVideoUrl} from "../utils/video.js"

const defaultState = {
  videoUrl: null
};

export default (state = defaultState, action) => {
  debug('state', state);
  switch (action.type) {
    case BOOK_OPEN:
      return {
        ...state,
        currentBook: action.payload
      };
    case BOOK_MODES_LOADED:
      const modes = action.payload;

      return {
        ...state,
        modePath: null,
        modes
      };
    case BOOK_LOADED: {
      const modePath = action.payload.modePath;
      const content = action.payload.content;

      return {
        ...state,
        modePath: modePath,
        pages: content.pages,
        bookContent: content
      };
    }
    case BOOK_GLOSSARY_LOADED: {
      const modePath = action.payload.modePath;

      return {
        ...state,
        modePath: modePath,
      };
    }
    case BOOK_GLOSSARY_DATA_LOADED: {
      const glossary = action.payload.content;

      return {
        ...state,
        bookGlossary: glossary
      };
    }
    case PAGE_LOAD:
      let pageIndex = parseInt(action.payload, 10),
          page = state.bookContent.pages[pageIndex];

      return {
        ...state,
        page,
        videoUrl: getVideoUrl(page, state.currentBook, state.modePath),
        videoShown: false
      };
    case VIDEO_LOAD:
      let keyword = action.payload;
      // TODO: use this if there's ever a book with more than one glossary.
      // but you will need to fix it to load linkedGlossary from payload, because linked_glossary config
      // doesn't exist on the actual glossary page!
      // let linkedGlossary = (
      //     state.bookContent
      //     && state.bookContent.config
      //     && state.bookContent.config.linked_glossary
      //   )
      //   || state.modePath;
      // linkedGlossary = linkedGlossary.toLowerCase()
      const linkedGlossary = 'data-glossary';

      return {
        ...state,
        videoUrl: getGlossaryVideoUrl(keyword, state.bookGlossary, state.currentBook, linkedGlossary),
        videoShown: false
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    default:
      return {
        ...state,
        videoShown: false
      };
  }
};
