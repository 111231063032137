import React, { Component } from 'react';
import { BOOK_LOAD_READ_STORYBOOK, REDIRECT, PAGE_LOAD } from '../actions/actionTypes';
import './App.css';
import Page from './Page';
import NavigationPane from './NavigationPane';
import TutorialOverlay from './TutorialOverlay';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import posed from 'react-pose'
import {spring} from 'popmotion'
import transform from 'dom-css-transform';

import {getBookDirUrl} from '../utils/video';
import Loading from './Loading';
import Snackbar from './Snackbar';


const mapStateToProps = (state, ownProps) => {
  let page = state.pages.page,
      pageIndex = parseInt(ownProps.match.params.pageIndex || "0", 10),
      numPages = state.pages.pages && state.pages.pages.length;
  
  return {
   pageIndex,
   error: state.error,
   pages: state.pages.pages,
   hasPagePrevious: pageIndex > 0,
   hasPageNext: pageIndex < numPages - 1,
   book: state.pages.currentBook,
   bookDir: getBookDirUrl(state.pages.currentBook)
  }
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload) =>
    dispatch({ type: BOOK_LOAD_READ_STORYBOOK, payload, skipTracking: true }),
  onRedirect: () =>
    dispatch({ type: REDIRECT })
});

function setTransform(el, style) {
  // el.style = 'transform: ' + style;
  transform(el, style);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.overPane = React.createRef();
  }

  componentWillMount() {
    this.props.onLoad({
      book: this.props.book,
      modePath: this.props.match.params.modePath
    });
  }

  onRibbonMove = (y) => {
    let pane = this.overPane.current;
    // console.log("onRibbonMove", y);
    
    if (pane) {
      setTransform(pane, 'translateY(' + y + 'px)');
    }
  }

  render() {
    if (!this.props.pages) {
      return <Loading />;
    }

    /**
     * How far down the ribbon can drag.
     */
    const isPhone = window.innerHeight < 450;
    const PANE_UNDER_HEIGHT = window.innerHeight - (isPhone ? 80 : 150);
    
    const ribbonConfig = {
        draggable: 'y',
        dragBounds: {
            top: 0,
            bottom: PANE_UNDER_HEIGHT
        },
        dragEnd: {
            transition: ({
                    from,
                    to,
                    velocity
                }) =>
                spring({
                    from,
                    to: (velocity > 0 || from == 0 ? PANE_UNDER_HEIGHT : 0),
                    velocity,
                    stiffness: 750,
                    damping: 50
                })
        }
    }

    const Ribbon = posed.div(ribbonConfig);

    const pageIndex = this.props.pageIndex;

    return (
      <div className="App">
        <div className='pane paneUnder'>
          <NavigationPane pageIndex={this.props.pageIndex} />
        </div>

        <Ribbon 
          className="ribbon" 
          onValueChange={{ y: this.onRibbonMove }}
          style={{backgroundImage: `url(${this.props.bookDir}/Content/ImageAssets/ribbon-toppage.png)`}}
          >
          <div 
            className='ribbonHandle'
            style={{backgroundImage: `url(${this.props.bookDir}/assets/read/page-ribbon.png)`}}
            />
        </Ribbon>

        <div className="pane paneOver" ref={this.overPane}>

          <Page pageIndex={pageIndex} />

          {this.props.hasPagePrevious &&
            <Link className="App-pagination App-pagination__prev" to={'./' + (this.props.pageIndex - 1)}>Previous</Link>
          }

          {this.props.hasPageNext &&
            <Link className="App-pagination App-pagination__next" to={'./' + (this.props.pageIndex + 1)}>Next</Link>
          }
        </div>

        { this.props.pageIndex === 1 && 
          <TutorialOverlay />
        }

        {this.props.error && 
          <Snackbar 
            message={this.props.error.message} 
            error={this.props.error.error} 
            />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
