import React, { Component } from 'react';
import './DownloadReadButton.css';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../../store';
import { BOOK_DOWNLOAD_START, BOOK_OPEN } from '../../actions/actionTypes';

import DownloadProgress from './DownloadProgress';

class DownloadErrorButton extends Component {

  render() {
    const book = this.props.book;
    const progress = this.props.progress;
    const label = this.props.label || 'Error';

    return (
      <div className={`downloadReadButton error read`} style={this.props.style}>
        <Link to={'#'} onClick={this.props.onClick}>{label}</Link>
      </div>
    );
  }
}

export default DownloadErrorButton;
