import { combineReducers } from 'redux';
import common from './reducers/common';
import pages from './reducers/pages';
import bookshelf from './reducers/bookshelf';
import {favoritesReducer} from './bookshelf/FavoritesMiddleware';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
  common,
  bookshelf,
  pages,
  favorites: favoritesReducer,
  router: connectRouter(history)
});
