import React, { Component } from 'react';
import './Bookshelf.css';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../../store';
import Book from './Book';
import { BOOKSHELF_FETCH, BOOK_OPEN } from '../../actions/actionTypes';
import Loading from '../Loading';
import Header from './Header';

import {sortBy} from 'lodash';

const mapStateToProps = (state, ownProps) => {
  // console.log('bookshelf', state);
  return {
   bookshelf: state.bookshelf.bookshelf,
   favorites: state.favorites.favorites
  }
};

const mapDispatchToProps = dispatch => ({

});

class Bookshelf extends Component {

  componentDidMount() {
    store.dispatch({type:BOOKSHELF_FETCH});
  }

  render() {
    let shelf = null;
    if (this.props.bookshelf) {
      if (this.props.bookshelf.is_bookshelf_active) {
        const books = this.props.bookshelf.stories.map(book => {
          return {
            ...book,
            isFavorite: !!this.props.favorites[book.stories_folder_name]
          }
        });
        // sortBy treats false as before true, but we want favorites first!
        const sortedStories = sortBy(books, b => !b.isFavorite);
        shelf = sortedStories.map(book => (
          <Book
            key={book.stories_folder_name}
            book={book}
          />
        ));
      } else {
        const book = this.props.bookshelf.stories[0];
        return <Redirect to={`book/${book.stories_folder_name}`} />;
      }
    } else {
      return <Loading />;
    }

    return (
      <div className="bookshelf">
        <Header />

        <div className="bookshelfContent">
          {shelf || <Loading />}    
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookshelf);
