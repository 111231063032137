import React, { Component } from 'react';
import './PageList.css';
import { Link } from 'react-router-dom'

class PageList extends Component {

  render() {
    const pages = this.props.pages.pages;
    const modePath = this.props.pages.modePath || 'data-storybook';
    const listItems = pages && pages.map((page, i) => {
      const isActiveClass = window.location.href.endsWith(`/${i}`);
      return <Link key={i} to={'./' + i} className={`PageList-thumbnail ${isActiveClass ? 'current' : ''}`}>
        <img alt={"Page " + i} src={`${this.props.bookDir}/${modePath}/` + page.page_thumbnail_image_asset} />
      </Link>
    });
    return (

      <div className="PageList-Container">
        <div className="PageList navlist">
          {listItems}
        </div>
      </div>
    );
  }
}

export default (PageList);
