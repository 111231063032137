// from https://docs.google.com/spreadsheets/d/1ue5VdmahPLXkzejMo6PsvauN7M3o1kuhQS6LhA6srn0/edit?ts=60921b12#gid=0
const translationLookup = {
  'Select a word': {
    'en':	'Select a word',
    'ru':	'Выберите слово',
    'nl':	'Kies een woord',
    'no':	'Velg et ord',
    'th':	'เลือกคำ',
    'ja':	'単語を選択してください',
    'es-pa':	'Selecciona una palabra',
    'ar-sa':	'اختر كلمة',
    'en-nz':	'Select a word',
    'tr':	'Bir kelime seçin',
  }
};

/**
 * Returns true if the user's language supports the given locale.
 *
 * @param {string} 2-letter locale code, e.g. 'en'
 */
export function isInPrimaryLocale(locale) {
  return navigator.language.startsWith(locale);
}

function findBestLocale(translations) {
  const locale = navigator.language;
  try {
    if (translations[locale]) {
      return translations[locale];
    }

    const lang = locale.substring(0, 2);
    if (translations[lang]) {
      return translations[lang];
    }

    for (var l in translations) {
      if (l.startsWith(lang)) {
        return translations[l];
      }
    }
  } catch (e) {
    console.error(`Failed to find translations in ${locale}`);
  }
}

export function translateString(str) {
  return findBestLocale(translationLookup[str]) || str;
};


/**
 * Return a locale-sensitive comparator function to be used with sort().
 */
export function createLocaleComparator() {
  try {
    if (typeof(Itnl) !== 'undefined') {
      const collator = new Intl.Collator(/*'en', {
        sensitivity: 'base'
      }*/);
      return collator.compare;
    }
  } catch (e) {
    console.error('Error instantiating Itnl.Collator', e);
  }

  if (typeof(''.localeCompare) !== 'undefined') {
    return (a, b) => a.localeCompare(
      b/*,
      undefined, {
        sensitivity: 'base'
      }*/
    );
  }

  return (a, b) => (a < b ? -1 : (a > b ? 1 : 0));
}
