/*global globalThis*/
import {
    BOOKSHELF_FETCH,
    BOOKSHELF_LOADED,
    BOOK_DOWNLOAD_STATE_UPDATE,
    BOOK_DOWNLOAD_START,
    BOOK_DOWNLOAD_PROGRESS,
    BOOK_DOWNLOAD_FINISHED,
    BOOK_OPEN,
    BOOK_LOADED,
    BOOK_GLOSSARY_LOADED,
    BOOK_DELETE,
  } from '../actions/actionTypes';
import { debug, isDebug, isLocalDebug } from '../utils/Debug';

// TODO(bookshelf): write a real one to communicate with android app
const fakeBookProgress = {};
const fakeBookDownloaderMiddleware = store => next => action => {
  if (action.type === BOOK_DOWNLOAD_START) {
    const bookName = action.payload.stories_folder_name;
    
    fakeBookProgress[bookName] = {
      progress: 0,
    };
    fakeBookProgress[bookName].intervalId = setInterval(() => {
      if (fakeBookProgress[bookName].progress >= 1) {
        clearInterval(fakeBookProgress[bookName].intervalId);
        store.dispatch({
          type: BOOK_DOWNLOAD_FINISHED,
          payload: {
            bookName
          }
        });
        return;
      }
      
      fakeBookProgress[bookName].progress += 0.01;
      store.dispatch({
        type: BOOK_DOWNLOAD_PROGRESS,
        payload: {
          bookName,
          progress: fakeBookProgress[bookName].progress
        }
      });
    }, 100);

    return;
  } else if (action.type === BOOK_DELETE) {
      const bookName = action.payload.stories_folder_name;
      
      delete fakeBookProgress[bookName];
      store.dispatch({
        type: BOOK_DOWNLOAD_STATE_UPDATE,
        payload: fakeBookProgress
      });

      return;
  }

  return next(action);
};


/**
 * Returns the JS interface injected by the Android app for bookshelf downloads.
 */
function getAndroidInterface() {
  const theGlobal = globalThis || Function('return this')();
  return theGlobal['_vl2_AndroidBookshelf'];
}

export function isAndroid() {
  return getAndroidInterface() !== undefined;
}

const androidBookDownloaderMiddleware = store => {
  document.addEventListener('_vl2_downloads', ev => {
    const downloadState = ev.detail;
    debug('download state:', downloadState);

    store.dispatch({
      type: BOOK_DOWNLOAD_STATE_UPDATE,
      payload: downloadState
    });
  });
  getAndroidInterface().subscribeToDownloadStatus();

  return next => action => {
    if (action.type === BOOK_DOWNLOAD_START) {
      const bookName = action.payload.stories_folder_name;
      getAndroidInterface().downloadBook(bookName);
  
      return;
    } else if (action.type === BOOK_DELETE) {
      const bookName = action.payload.stories_folder_name;
      getAndroidInterface().deleteBook(bookName);
  
      return;
    }
    
    return next(action);
  };
};

export function bookDownloaderMiddleware(store) {
  if (isAndroid()) {
    console.log("Using android book downloader");
    return androidBookDownloaderMiddleware(store);
  } else {
    console.log("Using fake web book downloader");
    return fakeBookDownloaderMiddleware(store);
  }
}

/** 
 * True if we're on Android, or if we're debugging. False if we're on the static website (public), in which case
 * all books are already "downloaded" and also can't be deleted.
 */
export function isBookDownloadingSupported() {
  return isLocalDebug || isAndroid();
}