// import agent from './agent';
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  REGISTER,
  BOOKSHELF_FETCH,
  BOOKSHELF_LOADED,
  BOOK_DOWNLOAD_START,
  BOOK_DOWNLOAD_PROGRESS,
  BOOK_DOWNLOAD_FINISHED,
  BOOK_OPEN,
  BOOK_LOADED,
  BOOK_MODES_LOADED,
  BOOK_LOAD_READ_STORYBOOK,
  BOOK_GLOSSARY_LOADED,
  BOOK_LOAD_GLOSSARY_DATA,
  BOOK_GLOSSARY_DATA_LOADED,
  REDIRECT,
  APP_LOAD,
  BOOK_LOAD_GLOSSARY,
  SHOW_ERROR
} from './actions/actionTypes';

import {getBookDirUrl} from './utils/video';

import {push} from 'connected-react-router';

let currentErrorTimeoutId = null;

const contentLoaderMiddleware = store => next => action => {
  const onError = (message, error) => {
    console.error(message, error);
    store.dispatch({
      type: SHOW_ERROR,
      payload: {message, error}
    });
    if (currentErrorTimeoutId !== null) {
      clearTimeout(currentErrorTimeoutId);
    }
    currentErrorTimeoutId = setTimeout(() => {
      store.dispatch({
        type: SHOW_ERROR,
        payload: null
      });
    }, 5000);
  };

  if (action.type === BOOKSHELF_FETCH) {
    fetch('assets/Content/bookshelf/bookshelf-config.json')
      .then(res => res.json())
      .then(bookshelf => {
        store.dispatch({
          type: BOOKSHELF_LOADED,
          payload: bookshelf
        });
      })
      .catch(e => {
        onError('Failed to fetch bookshelf json', e);
      });
  } else if (action.type === BOOK_OPEN) {
    const book = action.payload;
    const bookDir = getBookDirUrl(book);
    fetch(`${bookDir}/storybookcreator-config.json`)
      .then(res => res.json())
      .then(content => {
        store.dispatch({
          type: BOOK_MODES_LOADED,
          payload: content.modes.filter(mode => mode.enabled)
        });
      })
      .catch(e => {
        // TODO: show error message UI?
        onError('Failed to fetch story modes json', e);
      });
  } else if (action.type === BOOK_LOAD_READ_STORYBOOK) {
    const book = action.payload.book;
    const bookDir = getBookDirUrl(book);
    const modePath = action.payload.modePath || 'data-storybook';
    fetch(`${bookDir}/${modePath}/storybook-data.json`)
      .then(res => res.json())
      .then(content => {
        // First load the glossary so that we have the config JSON needed for loading
        // glossary videos from the correct path
        let linkedGlossary = (
              content
              && content.config
              && content.config.linked_glossary
            )
            || 'data-glossary';
        linkedGlossary = linkedGlossary.toLowerCase();

        store.dispatch({
          type: BOOK_LOAD_GLOSSARY_DATA,
          payload: {
            bookDir,
            modePath: linkedGlossary
          }
        });

        // Load book AFTER glossary
        store.dispatch({
          type: BOOK_LOADED,
          payload: {
            content,
            modePath
          }
        });
      })
      .catch(e => {
        // TODO: show error message UI?
        onError('Failed to fetch story json', e);
      });

  } else if (action.type === BOOK_LOAD_GLOSSARY_DATA) {
    const bookDir = action.payload.bookDir;
    const modePath = action.payload.modePath || 'data-glossary';
    fetch(`${bookDir}/${modePath}/glossary-data.json`)
      .then(res => res.json())
      .then(content => {
        store.dispatch({
          type: BOOK_GLOSSARY_DATA_LOADED,
          payload: {
            content
          }
        });
      })
      .catch(e => {
        // TODO: show error message UI?
        console.error('Failed to fetch story json', e);
      });

  } else if (action.type === BOOK_LOAD_GLOSSARY) {
    const bookDir = action.payload.bookDir;
    const modePath = action.payload.modePath || 'data-glossary';
    store.dispatch({
      type: BOOK_LOAD_GLOSSARY_DATA,
      payload: {
        bookDir,
        modePath
      }
    });

    store.dispatch({
      type: BOOK_GLOSSARY_LOADED,
      payload: {
        modePath
      }
    });

  } else if (action.type === BOOK_LOADED) {
    const book = action.payload;
    // Wait for the reducers to set currentBook on the state before we load things that need it
    // setTimeout(() => store.dispatch(push(`book/${book.stories_folder_name}`)), 10);
  } else if (action.type === APP_LOAD) {
    // if (!store.getState().pages) {
    //   store.dispatch({ type: BOOKSHELF_FETCH });
    // }
  }

  return next(action);
};

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      error => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        console.log('ERROR', error);
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  return next(action);
};

const localStorageMiddleware = store => next => action => {
  if (action.type === REGISTER || action.type === LOGIN) {
    if (!action.error) {
      window.localStorage.setItem('jwt', action.payload.user.token);
      // agent.setToken(action.payload.user.token);
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem('jwt', '');
    // agent.setToken(null);
  }

  return next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}


export { contentLoaderMiddleware, promiseMiddleware, localStorageMiddleware }
