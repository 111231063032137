import React, { Component } from 'react';
import { APP_LOAD, REDIRECT, PAGE_LOAD } from '../actions/actionTypes';
import './NavigationPane.css';
import PageList from './PageList';
import HomeLinks from './HomeLinks';
import { connect } from 'react-redux';
import { store } from '../store';
import { Link } from 'react-router-dom'

import {getBookDirUrl} from '../utils/video';

const mapStateToProps = (state, ownProps) => {
  let pages = state.pages;
  
  return {
   pages,
   bookDir: getBookDirUrl(state.pages.currentBook)
  }
};

const mapDispatchToProps = dispatch => ({
  onPageLoad: (payload) =>
    dispatch({ type: PAGE_LOAD, payload })
});

class NavigationPane extends Component {

  componentWillMount() {

  }

  render() {
    const modePath = this.props.pages.modePath || 'data-storybook';
    return (
      <div
        className="NavigationPane"
        style={{backgroundImage: `url(${this.props.bookDir}/${modePath}/assets/indexview-background.png)`}}
        >
        <PageList
          bookDir={this.props.bookDir}
          pages={this.props.pages} />

        <HomeLinks
          bookDir={this.props.bookDir}
          showHome={true} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPane);
