import React, { Component } from 'react';
import './BookPreview.css';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../../store';
import { BOOK_DOWNLOAD_START, BOOK_OPEN, BOOK_DELETE } from '../../actions/actionTypes';

import { debug } from '../../utils/Debug';
import DownloadProgress from './DownloadProgress';
import DownloadReadButton from './DownloadReadButton';
import DownloadErrorButton from './DownloadErrorButton';
import FavoriteButton from './FavoriteButton';

const mapStateToProps = (state, ownProps) => {
  debug('book preview', state);
  const downloadStatus = state.bookshelf.downloads[ownProps.book.stories_folder_name];
  return {
    isDownloaded: downloadStatus && downloadStatus.isDownloaded,
    progress: downloadStatus && downloadStatus.progress,
    error: downloadStatus && downloadStatus.error,
    currentBook: state.pages.currentBook
  }
};

const mapDispatchToProps = dispatch => ({

});

class BookPreview extends Component {

  render() {
    const book = this.props.book;
    // if (book === this.props.currentBook) {
    //   return (<Redirect to="/book" />);
    // }
    const deleteableClass = this.props.isDownloaded ? 'deletable' : '';

    const onClose = (ev) => {
      ev.stopPropagation();
      this.props.onClose();
      return false;
    };
    const onDialogClick = (ev) => {
      ev.stopPropagation();
      return false;
    };

    const onClickDelete = () => {
      store.dispatch({type: BOOK_DELETE, payload: this.props.book});
    }
    const onClickDownload = (ev) => {
      ev.stopPropagation();
      store.dispatch({type: BOOK_DOWNLOAD_START, payload: this.props.book});
      return false;
    };

    return (
      <div className={`bookPreviewOverlay ${deleteableClass}`} onClick={onClose}>
        
        <div className="bookPreview" onClick={onDialogClick}>
          <button className='close' onClick={onClose}>Close</button>

          <FavoriteButton 
            book={book} />

          <div className="bookPreviewContent">
            <div className="bookPreviewHeader">
              <img src={'assets/Content/bookshelf/' + book.bookshelf_display.thumbnail} alt={book.bookshelf_display.title} />

              <div className='bookPreviewHeaderInfo'>
                <h2>{book.bookshelf_display.title}</h2>
                <p className='author'><strong>Author:</strong> {book.author}</p>
                <p className='author'><strong>Storyteller:</strong> {book.storyteller}</p>
                <p className='author'><strong>Illustrator:</strong> {book.illustrator}</p>
              </div>
            </div>

            <div className="bookPreviewBody">
              <p>{book.bookshelf_display.description}</p>
            </div>
          </div>
          <div className="bottomBar">              
            {this.props.error ? 
              <DownloadErrorButton 
                onClick={onClickDownload}
                label="Retry" />
              :
              <DownloadReadButton 
                book={book} />
            }
            
            <p className='error'>{this.props.error ? `Error: ${this.props.error}` : ''}</p>

            <button className="deleteButton" onClick={onClickDelete}>Delete book</button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookPreview);
