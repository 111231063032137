import React, { Component } from 'react';
import './VideoPlayer.css'

import { VIDEO_END, VIDEO_START } from '../actions/actionTypes';
import { connect } from 'react-redux';
import Loading from './Loading';
import { debug } from '../utils/Debug';

const mapDispatchToProps = dispatch => ({
  onEnded: (payload) =>
    dispatch({ type: VIDEO_END, payload }),
  onStart: (payload) =>
    dispatch({ type: VIDEO_START, payload }),
});

const mapStateToProps = (state, ownProps) => {
  return {
   shown: state.pages.videoShown || state.common.videoShown
  }
};

class VideoPlayer extends Component {
  
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const vid = this.videoRef.current;
    const container = this.containerRef.current;

    debug('Video componentDidUpdate', this.props);

    if (!vid || !container) return;
    if (this.props.shown) {
      // Note: this doesn't always get called for some reason, so we also call playVideo() from render()
      container.style.display = 'block';
      vid.currentTime = 0;
      this.playVideo();
    } else {
      vid.pause();

      // Hide after animation, but only if the parent wants. otherwise this screws with the glossary somehow.
      if (this.props.displayNoneWhenDone) {
        setTimeout(() => container.style.display = 'none', 400);
      }
    }
  }

  playVideo() {
    try {
      const vid = this.videoRef.current;
      vid.play()
        .catch(e => {
          console.error('Error playing video:', e);
        });
    } catch (e) {
      console.error('Error playing video:', e);
    }
  }

  onLoadStart = () => {
    debug('Video Load start', this.props);
  }

  onReady = () => {
    debug('Video Loaded', this.props);
  }

  render() {
    const self = this;
    const playVideo = () => self.playVideo();

    if (this.props.shown) {
      // for some reason didComponentUpdate doesn't get called sometimes? e.g. click glossary video, then click play on page video
      // so we call this as a backup
      setTimeout(() => this.playVideo(), 200);
    }
    const theClass = `VideoPlayer ${this.props.shown ? 'shown' : 'hidden'}`;
    return (
      <div className={theClass} ref={this.containerRef} onClick={playVideo} >
        <Loading />
         
        {this.props.url && 
          <video src={this.props.url} onEnded={this.props.onEnded} ref={this.videoRef} 
            controls={this.props.showControls} 
            poster="/images/transparent.png"
            onCanPlayThrough={this.onReady}
            onLoadStart={this.onLoadStart}
            />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
