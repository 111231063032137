import React, { Component } from 'react';
import './Header.css';

const Header = (props) => {
    return ( 
        <header>
            <h1
                style={{
                    backgroundImage: 'url(./assets/Content/bookshelf/assets/bookshelf-index-header.png)'
                }}>
                ASL Storybooks
            </h1>
        </header>
     );
}
 
export default Header;