import React, { Component } from 'react';
import './Info.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from '../store';
import HomeLinks from './HomeLinks';

import {getBookDirUrl} from '../utils/video';

const mapStateToProps = (state, ownProps) => {
  let page = ownProps.match.params.page || 'about';
  const bookDir = getBookDirUrl(state.pages.currentBook);
  return {
   page,
   bookDir,
   url: bookDir + '/info/' + page + '.html'
  }
};

const mapDispatchToProps = dispatch => ({

});

class Info extends Component {

  render() {
    // Swap out image on touchdown
    const
      onLinkTouchStart = (ev) => {
        const img = ev.target;
        if (img.src.includes('-highlighted.png')) return;
        img.src = img.src.replace('.png', '-highlighted.png');
      },
      onLinkTouchEnd = (ev) => {
        const img = ev.target;
        img.src = img.src.replace('-highlighted.png', '.png');
      };

    return (
      <div 
        className="Info" 
        style={{backgroundImage: `url(${this.props.bookDir}/assets/info/background.png)`}}>
          <header 
            style={{backgroundImage: `url(${this.props.bookDir}/assets/info/info-topnav.png)`}}>
          	<Link to="./about" className="Info-Link Info-Link_about">
              <img
                src={this.props.bookDir + "/assets/info/button-info-about.png"}
                alt="About"
                onTouchStart={onLinkTouchStart}
                onTouchEnd={onLinkTouchEnd}
                onTouchCancel={onLinkTouchEnd} />
            </Link>
          	<Link to="./credits" className="Info-Link Info-Link_credits">
              <img
                src={this.props.bookDir + "/assets/info/button-info-credits.png"}
                alt="Credits"
                onTouchStart={onLinkTouchStart}
                onTouchEnd={onLinkTouchEnd}
                onTouchCancel={onLinkTouchEnd} />
            </Link>
          	<Link to="./morebooks" className="Info-Link Info-Link_morebooks">
              <img
                src={this.props.bookDir + "/assets/info/button-info-morebooks.png"}
                alt="More"
                onTouchStart={onLinkTouchStart}
                onTouchEnd={onLinkTouchEnd}
                onTouchCancel={onLinkTouchEnd} />
            </Link>
          </header>

          <div className="Info-content">
        	<iframe title="Content" src={this.props.url} />
          </div>

          <HomeLinks 
            showOnlyHome={true}
            bookDir={this.props.bookDir}
             />
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
