import React, { Component } from 'react';
import './Home.css';
import HomeLinks from './HomeLinks';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import { VIDEO_START, VIDEO_END } from '../actions/actionTypes';
import VideoPlayer from './VideoPlayer';

import {getBookDirUrl} from '../utils/video';

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { debug } from '../utils/Debug';

const mapStateToProps = (state, ownProps) => {
	const locationState = state.router.location.state;
	const skipIntro = locationState && locationState.skipIntro;

	return {
    matchUrl: ownProps.match.url,
    skipIntro,
    bookDir: getBookDirUrl(state.pages.currentBook),
    isHidden: !skipIntro && (state.pages.videoShown === true || state.common.videoShown === true)
	}
};

const mapDispatchToProps = dispatch => ({
  showVideo: (payload) =>
    dispatch({ type: VIDEO_START, payload }),
  hideVideo: (payload) =>
    dispatch({ type: VIDEO_END, payload }),
});


class Home extends Component {

  componentDidMount() {
  	this.props.showVideo();
  }

  render() {
    debug('render Home ' + this.props.skipIntro, this.props);
    return (
      <div className={"Home" + (this.props.isHidden ? " hidden" : "")}>

        {!this.props.skipIntro && 
            <VideoPlayer 
              url={this.props.bookDir + "/appstartup/startup-video.mp4"}
              displayNoneWhenDone={true} />
        }

        <div 
          className="HomeBackground"
          style={{backgroundImage: `url(${this.props.bookDir}/assets/home/background.png)`}}
          >
          <HomeLinks 
            showHome={false}
            bookDir={this.props.bookDir}
            />

          <Link 
            to={`./info`} 
            className="Home-Link Home-Link-Floating Home-Link_info"
            css={css`
            background-image: url('${this.props.bookDir}/assets/info/button-info.png');
            
            &:active {
              background-image: url('${this.props.bookDir}/assets/info/button-info-highlighted.png');
            }
            `}
            >
              Info
          </Link>

          <Link 
            to={`/`}
            className='backToBookshelf'
            css={css`
            background-image: url('${this.props.bookDir}/assets/home/button-bookshelf.png');
            `}
            >
              Back to bookshelf
            </Link>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
