import { LOCATION_CHANGE } from 'connected-react-router';
import {
  APP_LOAD,
  REDIRECT,
  VIDEO_END,
  VIDEO_START,
  SHOW_ERROR,
  BOOK_LOADED,
  BOOK_GLOSSARY_LOADED,
} from '../actions/actionTypes';
import { debug } from '../utils/Debug';

const defaultState = {
  videoShown: false
};

export default (state = defaultState, action) => {
  debug('common state ', action.type, state);
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true
      };
    case SHOW_ERROR:
      return {
        ...state,
        errorMessage: action.payload 
      };
    case VIDEO_START:
      return {
        ...state,
        videoShown: true
      };
    case VIDEO_END:
      return {
        ...state,
        videoShown: false
      };
    case BOOK_LOADED:
      // fall-through!
    case BOOK_GLOSSARY_LOADED:
      // do not cancel the intro video showing!
      return {
        ...state
      };
    case LOCATION_CHANGE:
      // make sure to cancel and hide any currently playing videos so it doesn't show the player
      return {
        ...state,
        videoShown: false
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    default:
      return {
        ...state,
        videoShown: false
      };
  }
};
