export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const PAGE_LOAD = 'PAGE_LOAD';
export const VIDEO_LOAD = 'VIDEO_LOAD';
export const VIDEO_END = 'VIDEO_END';
export const VIDEO_START = 'VIDEO_START';

export const SHOW_ERROR = 'SHOW_ERROR';

// Bookshelf
export const BOOKSHELF_FETCH = 'BOOKSHELF_FETCH';
export const BOOKSHELF_LOADED = 'BOOKSHELF_LOADED';
export const BOOK_DOWNLOAD_STATE_UPDATE = 'BOOK_DOWNLOAD_STATE_UPDATE';
export const BOOK_DOWNLOAD_START = 'BOOK_DOWNLOAD_START';
export const BOOK_DOWNLOAD_PROGRESS = 'BOOK_DOWNLOAD_PROGRESS';
export const BOOK_DOWNLOAD_FINISHED = 'BOOK_DOWNLOAD_FINISHED';
export const BOOK_OPEN = 'BOOK_OPEN';
export const BOOK_LOADED = 'BOOK_LOADED';
export const BOOK_LOAD_GLOSSARY = 'BOOK_LOAD_GLOSSARY';
export const BOOK_GLOSSARY_LOADED = 'BOOK_GLOSSARY_LOADED';
export const BOOK_GLOSSARY_DATA_LOADED = 'BOOK_GLOSSARY_DATA_LOADED';
export const BOOK_LOAD_GLOSSARY_DATA = 'BOOK_LOAD_GLOSSARY_DATA';
export const BOOK_MODES_LOADED = 'BOOK_MODES_LOADED';
export const BOOK_LOAD_READ_STORYBOOK = 'BOOK_LOAD_READ_STORYBOOK';
export const BOOK_DELETE = 'BOOK_DELETE';

export const BOOK_SAVE_FAVORITES = 'BOOK_TOGGLE_FAVORITE';
export const BOOK_FAVORITES_LOADED = 'BOOK_FAVORITES_LOADED';


export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
