import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Loading from './Loading';
import Home from './Home';
import Glossary from './Glossary';
import Info from './Info';
import Watch from './Watch';

import { connect } from 'react-redux';

import { Redirect, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store, history} from '../store';
import { BOOK_OPEN } from '../actions/actionTypes';


const mapStateToProps = (state, ownProps) => {
  let bookUrl = ownProps.match.params.book;
  return {
    matchUrl: ownProps.match.url,
    bookUrl,
    currentBook: state.pages.currentBook,
    pages: state.pages
  }
};

const mapDispatchToProps = dispatch => ({

});

class BookRouter extends Component {

  render() {
    if (!this.props.currentBook || this.props.currentBook.stories_folder_name !== this.props.bookUrl) {
      store.dispatch({
        type: BOOK_OPEN,
        payload: {
          // TODO(fuego): yes this is cheap. might need a better way
          stories_folder_name: this.props.bookUrl
        }
      });
      return (
        <Loading />
      );
    }

    const matchUrl = this.props.matchUrl;
    return (
      <Switch>
        <Route path={`${matchUrl}/watch/:modePath`} exact component={Watch} />
        <Route path={`${matchUrl}/watch`} exact component={Watch} />

        <Route path={`${matchUrl}/glossary`} exact strict>
          <Redirect to={`${matchUrl}/glossary/index`}/>
        </Route>
        <Route path={`${matchUrl}/glossary/index`} exact component={Glossary} />
        <Route path={`${matchUrl}/glossary/:word`} component={Glossary} />

        <Route path={`${matchUrl}/learn/:modePath`} exact strict render= {({match}) => (
          <Redirect to={`${matchUrl}/learn/${match.params.modePath}/index`}/>
        )} />
        <Route path={`${matchUrl}/learn/:modePath/index`} exact component={Glossary} />
        <Route path={`${matchUrl}/learn/:modePath/:word`} component={Glossary} />

        <Route path={`${matchUrl}/info`} exact strict>
          <Redirect to={`${matchUrl}/info/index`}/>
        </Route>
        <Route path={`${matchUrl}/info/index`} exact component={Info} />
        <Route path={`${matchUrl}/info/:page`} component={Info} />

        <Route path={`${matchUrl}/read/:modePath`} exact strict render= {({match}) => (
          <Redirect to={`${matchUrl}/read/${match.params.modePath}/page/0`}/>
        )} />
        <Route path={`${matchUrl}/read/:modePath/page/:pageIndex`} component={App} />

        <Route path={`${matchUrl}/page/:pageIndex`} component={App} />
        <Route path={`${matchUrl}/index`} exact strict component={Home} />
        <Route path={``} exact strict>
          <Redirect to={`${matchUrl}/index`}/>
        </Route>
      </Switch>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookRouter);